import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.xprofitsminiapp.com',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Универсальная функция для выполнения запросов
export const fetchData = async (endpoint, options = {}) => {
    try {
        const response = await api({
            url: endpoint,
            method: options.method || 'GET',
            data: options.data || null,
            params: options.params || null,
            headers: {
                ...api.defaults.headers,
                ...options.headers,
            },
        });

        return response.data;
    } 
    catch (error) {
        console.error('API fetch error:', error);
        throw error.response ? error.response.data : error;
    }
};