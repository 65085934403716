import React from 'react';
import useFetch from '../../hooks/useFetch';
import ProfitList from '../../components/ProfitList';
import styles from "./index.module.scss";
import Header from '../../components/Header';

const Home = () => {
  return (
    <div className={styles.home}>
      <div className="container">
        <Header/>
        <ProfitList/>
      </div>
       
    </div>
  );
};

export default Home;
