export function getProfitColor(profit) {
    let color = "red";

    if (profit < 5000) 
        color = "gray"
    else if (profit < 15000)
        color = "blue"
    else if (profit < 50000) 
        color = "green"

    return color;
}

export function formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    
    // Формат времени в формате "чч:мм"
    const timeString = date.toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit'
    });
  
    // Если дата совпадает с сегодняшней, вернуть только время
    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      return timeString;
    }
  
    // Если дата совпадает с вчерашним днем, вернуть "вчера, в чч:мм"
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    
    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return `вчера, в ${timeString}`;
    }
  
    // Для всех остальных случаев вернуть "дд.мм.гггг"
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  

export const TRANSLATED_DATA = {
  notTP: "НЕ TП",
  tp: "ТП",
  call: "ПРОЗВОН",
  directTransfer: "ПРЯМОЙ ПЕРЕВОД",
  callTP: "ПРОЗВОН + ТП",
  trade: "TRADE",
  tradeNW: "TRADE NW",
  ESCORT: "ESCORT",
  NFT: "NFT",
  CASINO: "CASINO",
  EXCHANGER: "EXCHANGER",
  "LUCKY JET": "LUCKY JET",
  "TRADE WEB": "TRADE WEB",
  "NFT WEB": "NFT WEB",
}