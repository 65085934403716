import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useProfitSearch = (offsetRef, limit) => {
    const storage = useSelector((state) => state.search);

    const buildSearchParams = useCallback((isAutoUpdate) => {
        const params = new URLSearchParams({
            limit: isAutoUpdate ? 10 : limit,
            offset: isAutoUpdate ? 0 : offsetRef.current,
            sum: storage.sum,
            user: storage.user,
            curator: storage.curator,
            base_search: storage.base_search,
            ...storage.checkboxes,
        });

        return params.toString();
    }, [offsetRef, limit, storage]);

    return buildSearchParams;
};
