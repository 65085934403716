import { useCallback } from 'react';
import { loadProfits } from '../services/profitsService';
import { setTotalProfits } from '../store/slices/searchSlice';

export const useProfitListData = ({
    profits,
    setProfits,
    setLoading,
    setError,
    offsetRef,
    storageRef,
    dispatch,
    buildSearchParams,
    updateNewProfitsCount,
    newProfitsCountRef
}) => {
    return useCallback(async (isNewSearch, isLoadingMore) => {
        if (isNewSearch || isLoadingMore) {
            setLoading(true);
        }

        const error = await loadProfits({
            isNewSearch,
            isLoadingMore,
            buildSearchParams,
            profits,
            offsetRef,
            storageRef,
            dispatch,
            setTotalProfits,
            setProfits,
            updateNewProfitsCount,
            newProfitsCountRef
        });

        if (error) {
            setError(error);
        }

        setLoading(false);
    }, [buildSearchParams, dispatch, profits]);
};