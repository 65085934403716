import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import LogoIcon from "../Images/Logo";
import SearchButton from "../SearchButton";
import { useState } from "react";
import Search from "../Search";

function Header() {
    const [isOpen, setIsOpen] = useState(false);

    function onClick() { setIsOpen(!isOpen); }
    const onReloadPage = () => {
        window.location.reload();
    };
    return (
        <div className={styles.header}>
            <div className={styles.header__top}>
                <div className={styles.header__info}>
                    <div className={styles.header__logo} onClick={onReloadPage}>
                        <LogoIcon/>
                    </div>

                    <div className={styles.header__name}>Оплаты</div>
                </div>

                <SearchButton isOpen={isOpen} onClick={onClick}/>
            </div>


            {isOpen && (
                <>
                    <div className={styles.header__search}>
                        <Search/>
                    </div>

                    <h1 className={styles["header__result-title"]}>Результаты</h1>
                </>
            )}

            
        </div>
    );
}

export default Header;