import { fetchData } from '../services/api';

export const loadProfits = async ({ 
    isNewSearch, 
    isLoadingMore, 
    buildSearchParams, 
    profits,
    offsetRef,
    storageRef,
    dispatch,
    setTotalProfits,
    setProfits,
    updateNewProfitsCount,
    newProfitsCountRef
}) => {
    try {
        const params = buildSearchParams(!isNewSearch && !isLoadingMore);
        const response = await fetchData(`/find?${params}`);

        if (!response || !Array.isArray(response.profits)) {
            throw new Error('Данные не в ожидаемом формате');
        }

        dispatch(setTotalProfits(response.total));

        if (isNewSearch) {
            setProfits(response.profits);
        } 
        else if (isLoadingMore) {
            const newProfits = response.profits.filter(
                profit => !profits.some(existing => existing.id === profit.id)
            );
            setProfits(prev => [...prev, ...newProfits]);
        }
        else {
            if (response.total > storageRef.current.total_profits) {
                const newProfits = response.profits.filter(
                    profit => !profits.some(existing => existing.id === profit.id)
                );
                
                console.log(`New profits created (${newProfits.length})`);
                setProfits(prev => [...newProfits, ...prev]);
                offsetRef.current += newProfits.length;
                updateNewProfitsCount(newProfitsCountRef.current + newProfits.length);
            }
        }

        return null; // Если нет ошибок
    } catch (err) {
        return err; // Возвращаем ошибку, если она возникла
    }
};