import styles from "./index.module.scss";
import { formatNumber } from "../../utils/convert";
import UserIcon from "../Images/User";
import { getProfitColor } from "../../utils/other";
import classNames from "classnames";
import { formatDate } from "../../utils/other";
import { TRANSLATED_DATA } from '../../utils/other';

function ProfitItem({data}) {
    const color = getProfitColor(data.sum);

    return ( 
        <li className={classNames(styles["profit-item"], styles[`profit-item--${color}`])} key={data.id}>
            <div className={styles["profit-item__header"]}>
                <div className={styles["profit-item__success"]}>Успешное пополнение</div>
                <div className={styles["profit-item__date"]}>{formatDate(data.date)}</div>
            </div>

            <div className={styles["profit-item__main"]}>
                <div className={styles["profit-item__deal-info"]}>
                    <div className={classNames(styles["profit-item__profit-value"], styles[`profit-item__profit-value--${color}`], styles[`profit-item__profit-value--v`])}>
                        +{formatNumber(data.sum)}
                    </div>

                    <div className={styles["profit-item__profit-from"]}>
                        {data.bot_type} 
                    </div>
                </div>
                
                <div className={styles["profit-item__user-data"]}>
                    <UserIcon/>

                    <div className={styles["profit-item__username"]}>{data.fake_user_name}</div>
                </div>

                <div className={styles["profit-item__transaction-type"]}>
                    {TRANSLATED_DATA[data.type]}
                </div>
    
            </div>
        </li>
    );
}

export default ProfitItem;