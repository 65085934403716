import { pxToRem } from "../../utils/convert";

function SearchIcon({ width = 20, height = 17, className }) {
    return ( 
        <div>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
               <g opacity="0.5">
                    <path d="M9.58333 15.4167C12.805 15.4167 15.4167 12.805 15.4167 9.58333C15.4167 6.36167 12.805 3.75 9.58333 3.75C6.36167 3.75 3.75 6.36167 3.75 9.58333C3.75 12.805 6.36167 15.4167 9.58333 15.4167Z" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.75 13.75L16.25 16.25" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>
        </div>
     );
}

export default SearchIcon;