import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './index.module.scss';
import React from 'react';
import { pxToRem } from '../../utils/convert';

function SkeletonProfitItem(id) {
  return (
    <li className={styles["profit-item"]} key={id}>
        <div className={styles["profit-item__header"]}>
            <Skeleton height={pxToRem(21)} width={pxToRem(186)} baseColor="#333" highlightColor="#444"/>
            <Skeleton height={pxToRem(20)} width={pxToRem(105)} baseColor="#333" highlightColor="#444"/>
        </div>

        <div className={styles["profit-item__main"]}>
            <div className={styles["profit-item__deal-info"]}>
                <div className={styles["profit-item__profit-value"]}>
                    <Skeleton height={pxToRem(26)} width={pxToRem(109)} baseColor="#333" highlightColor="#444"/>
                </div>

                <Skeleton borderRadius={pxToRem(7)} height={pxToRem(28)} width={pxToRem(118)} baseColor="#333" highlightColor="#444"/>
            </div>
            
            <div className={styles["profit-item__user-data"]}>
                <Skeleton height={pxToRem(20)} width={pxToRem(136)} baseColor="#333" highlightColor="#444"/>
            </div>

            <Skeleton borderRadius={pxToRem(7)}  style={{marginTop: pxToRem(15)}} height={pxToRem(28)} width={"100%"} baseColor="#333" highlightColor="#444"/>
        </div>
    </li>
  );
}

export default SkeletonProfitItem;
