import { pxToRem } from "../../utils/convert";

function TickIcon({ width = 17, height = 17, className }) {
    return ( 
        <div style={{width: pxToRem(width), height: pxToRem(height)}}>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
               <path d="M4.25 8.76208L6.91333 11.4183L12.75 5.58167" stroke="#191919" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
     );
}

export default TickIcon;