import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sum: '',
  user: '',
  curator: '',
  base_search: '',
  advanced_search: false,
  total_profits: 1,
  checkboxes: {
    notTP: false,
    tp: false,
    call: false,
    directTransfer: false,
    callTP: false,
    trade: false,
    tradeNW: false,
    escort: false,
    nft: false,
    casino: false,
    exchanger: false,
    luckyJet: false,
    tradeWeb: false,
    nftWeb: false,
  }
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSum: (state, action) => {
      console.log(action.payload);
      state.sum = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCurator: (state, action) => {
      state.curator = action.payload;
    },
    toggleCheckbox: (state, action) => {
      const { name } = action.payload;
      state.checkboxes[name] = !state.checkboxes[name];
    },
    setBaseSearch: (state, action) => {
      state.base_search = action.payload;
    },
    toggleAdvancedSearch: (state, action) => {
      state.advanced_search = action.payload;
    },
    setTotalProfits: (state, action) => {
      state.total_profits = action.payload;
    },
    
    resetSearch: () => initialState, // Optional: Reset state to initial values
  }
});

export const { setSum, setUser, setCurator, toggleCheckbox, resetSearch, setBaseSearch, setTotalProfits } = searchSlice.actions;
export default searchSlice.reducer;
