export const pxToRemDefault = (px, base = 16) => px / base; // Функция перевода px в rem
export const pxToRem = (px, base = 16) => `${pxToRemDefault(px, base)}rem`; // Функция перевода px в rem

export function formatNumber(number, sign = ' ') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sign);
}

export function formatReplenishments(count) {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return `+${count} пополнений`;
    }
    
    if (lastDigit === 1) {
        return `+${count} пополнение`;
    }
    
    if (lastDigit >= 2 && lastDigit <= 4) {
        return `+${count} пополнения`;
    }

    return `+${count} пополнений`;
}