import styles from "./index.module.scss";
import SmallTextBox from "./SmallTextBox";

function TextBox( { 
    placeholder, 
    Icon, 
    isSmall, 
    isReversed, 
    labelText, 
    width, 
    onChange, 
    type = "text"
 }) {
    if (isSmall) {
        return <SmallTextBox 
            placeholder={placeholder}
            Icon={Icon}
            isReversed={isReversed}
            labelText={labelText}
            width={width}
            onChange={onChange}
            type={type}
        />
    }
    return (
        <div className={styles.textbox}>
            <input type={type} placeholder={placeholder} className={styles.textbox__input} onChange={onChange} />
            <Icon className={styles.textbox__icon}/>
        </div>
    );
}

export default TextBox;