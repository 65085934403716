import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import TextBox from '../TextBox';
import SearchIcon from '../Images/Search';
import RubIcon from '../Images/Rub';
import UserIcon from '../Images/User';
import textboxStyles from "../TextBox/index.module.scss";
import Checkbox from '../CheckBox';
import classNames from 'classnames';
import { setSum, setUser, setCurator, toggleCheckbox, setBaseSearch } from '../../store/slices/searchSlice';
import { pxToRem } from '../../utils/convert';

const Search = () => {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const dispatch = useDispatch();
  
  // Получение значений из хранилища
  const { sum, user, curator, checkboxes } = useSelector((state) => state.search);

  // Обработчики для TextBox
  const handleBaseSearchChange = (e) => dispatch(setBaseSearch(e.target.value));
  const handleSumChange = (e) => dispatch(setSum(e.target.value));
  const handleUserChange = (e) => dispatch(setUser(e.target.value));
  const handleCuratorChange = (e) => dispatch(setCurator(e.target.value));

  // Обработчик для чекбоксов
  const handleCheckboxChange = (name) => {
    dispatch(toggleCheckbox({ name }));
  };

  return (
    <div className={styles['search-container']}>
      <div className={styles['search-toggle']}>
        <button
          className={`${styles['search-toggle__button']} ${!isAdvanced ? styles['search-toggle__button--active'] : ''}`}
          onClick={() => setIsAdvanced(false)}
        >
          Обычный поиск
        </button>
        <button
          className={`${styles['search-toggle__button']} ${isAdvanced ? styles['search-toggle__button--active'] : ''}`}
          onClick={() => setIsAdvanced(true)}
        >
          Расширенный поиск
        </button>
      </div>

      {!isAdvanced ? (
        <div className={`${styles['search-content']} ${styles['search-content--regular']}`}>
            <TextBox 
              placeholder="Введите запрос" 
              Icon={SearchIcon} 
              onChange={handleBaseSearchChange}
            />
        </div>
      ) : (
        <div className={`${styles['search-content']} ${styles['search-content--advanced']}`}>
          <div className={styles['inputs-group']}>
            <TextBox 
              isSmall={true} 
              type="number"
              labelText="Сумма" 
              Icon={RubIcon}
              width={pxToRem(149)}
              value={sum}
              onChange={handleSumChange}
            />
            <TextBox 
              isSmall={true} 
              labelText="Пользователь" 
              Icon={UserIcon}
              width={pxToRem(210)}
              isReversed={true}
              value={user}
              onChange={handleUserChange}
            />
            <TextBox 
              isSmall={true} 
              labelText="Куратор" 
              Icon={UserIcon}
              width={pxToRem(210)}
              isReversed={true}
              value={curator}
              onChange={handleCuratorChange}
            />
          </div>

          <div className={styles['checkbox-group']}>
            <p className={textboxStyles['textbox-label']}>Тип перевода</p>
            <div className={styles['checkbox-items']}>
              <Checkbox
                label="НЕ ТП"
                checked={checkboxes.notTP}
                onChange={() => handleCheckboxChange('notTP')}
              />
              <Checkbox
                label="ТП"
                checked={checkboxes.tp}
                onChange={() => handleCheckboxChange('tp')}
              />
              <Checkbox
                label="Прозвон"
                checked={checkboxes.call}
                onChange={() => handleCheckboxChange('call')}
              />
              <Checkbox
                label="Прямой перевод"
                checked={checkboxes.directTransfer}
                onChange={() => handleCheckboxChange('directTransfer')}
              />
              <Checkbox
                label="Прозвон + ТП"
                checked={checkboxes.callTP}
                onChange={() => handleCheckboxChange('callTP')}
              />
            </div>
          </div>

          <div className={classNames(styles['checkbox-group'], styles['checkbox-group--service'])}>
            <p className={textboxStyles['textbox-label']}>Сервис</p>
            <div className={styles['checkbox-items']}>
              <Checkbox
                label="TRADE"
                checked={checkboxes.trade}
                onChange={() => handleCheckboxChange('trade')}
              />
              <Checkbox
                label="TRADE NW"
                checked={checkboxes.tradeNW}
                onChange={() => handleCheckboxChange('tradeNW')}
              />
              <Checkbox
                label="ESCORT"
                checked={checkboxes.escort}
                onChange={() => handleCheckboxChange('escort')}
              />
              <Checkbox
                label="NFT"
                checked={checkboxes.nft}
                onChange={() => handleCheckboxChange('nft')}
              />
              <Checkbox
                label="CASINO"
                checked={checkboxes.casino}
                onChange={() => handleCheckboxChange('casino')}
              />
              <Checkbox
                label="EXCHANGER"
                checked={checkboxes.exchanger}
                onChange={() => handleCheckboxChange('exchanger')}
              />
              <Checkbox
                label="LUCKY JET"
                checked={checkboxes.luckyJet}
                onChange={() => handleCheckboxChange('luckyJet')}
              />
              <Checkbox
                label="TRADE WEB"
                checked={checkboxes.tradeWeb}
                onChange={() => handleCheckboxChange('tradeWeb')}
              />
              <Checkbox
                label="NFT WEB"
                checked={checkboxes.nftWeb}
                onChange={() => handleCheckboxChange('nftWeb')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default Search;