import { pxToRem } from "../../utils/convert";

function BackIcon({ width = 20, height = 20, className }) {
    return ( 
        <div style={{width: pxToRem(width), height: pxToRem(height)}}>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 20 20`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path opacity="0.5" d="M10 0C11.9778 0 13.9112 0.58649 15.5557 1.6853C17.2002 2.78412 18.4819 4.3459 19.2388 6.17316C19.9957 8.00042 20.1937 10.0111 19.8078 11.9509C19.422 13.8907 18.4696 15.6725 17.0711 17.0711C15.6725 18.4696 13.8907 19.422 11.9509 19.8078C10.0111 20.1937 8.00042 19.9957 6.17316 19.2388C4.3459 18.4819 2.78412 17.2002 1.6853 15.5557C0.586489 13.9112 0 11.9778 0 10C0 7.34783 1.05357 4.80429 2.92893 2.92893C4.80429 1.05357 7.34783 0 10 0ZM7.77778 10.5889L11.1111 13.9222C11.2674 14.0783 11.4792 14.1659 11.7 14.1659C11.9208 14.1659 12.1326 14.0783 12.2889 13.9222C12.4449 13.766 12.5326 13.5542 12.5326 13.3333C12.5326 13.1125 12.4449 12.9007 12.2889 12.7444L9.51111 10L12.2222 7.25555C12.3238 7.09893 12.3698 6.91276 12.3529 6.72686C12.336 6.54097 12.2572 6.36613 12.1292 6.23035C12.0011 6.09458 11.8311 6.00574 11.6465 5.97808C11.4619 5.95041 11.2734 5.98552 11.1111 6.07778L7.77778 9.41111C7.62172 9.56736 7.53406 9.77916 7.53406 10C7.53406 10.2208 7.62172 10.4326 7.77778 10.5889Z" fill="white"/>
            </svg>
        </div>
     );
}

export default BackIcon;