import { pxToRem } from "../../utils/convert";

function LogoIcon({ width = 26, height = 24, className }) {
    return ( 
        <div style={{width: pxToRem(width), height: pxToRem(height)}}>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 22 22`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M17.4844 9.00283L25.6222 2.96331L20.5041 0.6245L14.8878 4.52767L10.6992 2.14487L4.36118 5.75464L9.78931 9.09723L3.57852 12.9606L0.43665 21.8769L12.8925 12.7344L23.496 23.6726L25.1162 14.2898L17.4844 9.00283Z" fill="#2B9FFF"/>
            </svg>
        </div>
     );
}

export default LogoIcon;