import { pxToRem } from "../../utils/convert";

function UserIcon({ width = 20, height = 20, className }) {
    return ( 
        <div style={{width: pxToRem(width), height: pxToRem(height)}}>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M10 8.17505C11.3807 8.17505 12.5 7.05576 12.5 5.67505C12.5 4.29434 11.3807 3.17505 10 3.17505C8.61929 3.17505 7.5 4.29434 7.5 5.67505C7.5 7.05576 8.61929 8.17505 10 8.17505Z" stroke="white" strokeOpacity="0.5" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 16.825C3.75 15.1673 4.40848 13.5776 5.58058 12.4055C6.75268 11.2334 8.3424 10.575 10 10.575C11.6576 10.575 13.2473 11.2334 14.4194 12.4055C15.5915 13.5776 16.25 15.1673 16.25 16.825" stroke="white" strokeOpacity="0.5" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
     );
}

export default UserIcon;