import { useCallback } from 'react';

export const useProfitListScroll = (listRef, loading, offsetRef, storageRef, limit, handleLoadProfits, updateNewProfitsCount) => {
    const handleScroll = () => {
        if (!listRef.current || loading) return;
        if (offsetRef.current >= storageRef.current.total_profits) return;

        const { scrollTop, scrollHeight, clientHeight } = listRef.current;

        if (Math.abs(scrollTop) + clientHeight + 500 >= scrollHeight) {
            console.log("Loading more profits... Current offset:", offsetRef.current);
            offsetRef.current += limit;
            handleLoadProfits(false, true);
        }
        else if (scrollTop > -10) {
            updateNewProfitsCount(0);
        }
    };
    
    return useCallback(() => {
        let timeout;
        return () => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(handleScroll, 2000);
        };
    }, [handleLoadProfits]);
};