import styles from "./index.module.scss";
import classNames from "classnames";

function SmallTextBox( { 
    placeholder, 
    Icon, 
    isReversed, 
    width = "100%", 
    labelText, 
    onChange,
    type = "text"
 }) {
    return (<div>
        {labelText && <label className={styles["textbox-label"]}>{labelText}</label>}
        <div 
            className={classNames(styles.textbox, styles["textbox--small"], 
                isReversed && styles["textbox--reversed"])}
                style={{width: width}}
            >
            <input 
                type={type} 
                placeholder={placeholder} 
                className={classNames(styles.textbox__input, styles["textbox__input--small"])} 
                onChange={onChange}
            />
            <Icon className={styles.textbox__icon}/>
        </div>
    </div>);
}

export default SmallTextBox;