import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useProfitSearch } from '../../hooks/useProfitSearch';
import { calculateListMaxHeight } from '../../utils/styles';
import { useProfitListScroll } from '../../hooks/useProfitListScroll';
import { useProfitListData } from '../../hooks/useProfitListData';
import { ListContent } from '../ListContent';
import NewProfitsInfo from '../NewProfitsInfo';
import styles from "./index.module.scss";
import { useWindowSize } from '../../hooks/useWindowSize';
import { pxToRem, pxToRemDefault } from '../../utils/convert';

const ProfitList = () => {
    const { width, height } = useWindowSize();

    const [profits, setProfits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newProfitsCount, setNewProfitsCount] = useState(0);
    
    const offsetRef = useRef(0);
    const listRef = useRef(null);
    const newProfitsCountRef = useRef(null);
    const limit = 20;

    const dispatch = useDispatch();
    const storage = useSelector((state) => state.search);
    const storageRef = useRef(storage);
    const buildSearchParams = useProfitSearch(offsetRef, limit);

    const updateNewProfitsCount = (count) => {
        newProfitsCountRef.current = count;
        setNewProfitsCount(count);
    }

    useEffect(() => {
        storageRef.current = storage;
    }, [storage]);

    const moveToStart = () => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
        updateNewProfitsCount(0);
    }

    const handleLoadProfits = useProfitListData({
        profits,
        setProfits,
        setLoading,
        setError,
        offsetRef,
        storageRef,
        dispatch,
        buildSearchParams,
        updateNewProfitsCount,
        newProfitsCountRef
    });

    const debouncedHandleScroll = useProfitListScroll(
        listRef,
        loading,
        offsetRef,
        storageRef,
        limit,
        handleLoadProfits,
        updateNewProfitsCount
    );

    useEffect(() => {
        const ref = listRef.current;
        if (ref) {
            const scrollHandler = debouncedHandleScroll();
            ref.addEventListener('scroll', scrollHandler);
            return () => {
                ref.removeEventListener('scroll', scrollHandler);
            };
        }
    }, [debouncedHandleScroll]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!loading) {
                handleLoadProfits(false, false);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [handleLoadProfits, loading]);

    useEffect(() => {
        offsetRef.current = 0;
        handleLoadProfits(true);

        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [
        storage.base_search,
        storage.advanced_search,
        storage.sum,
        storage.user,
        storage.curator,
        storage.checkboxes
    ]);

    if (error) return <div>Ошибка: {error.message}</div>;

    let maxHeight = 90 - height / 85;

    
    return (
        <div className={styles.wrapper}>
            <div 
                ref={listRef} 
                className={styles.list} 
                style={{ 
                    maxHeight: `${maxHeight}vh`
                }}>
                <ul className={styles["list__items"]}>
                    <ListContent loading={loading} profits={profits} />
                </ul>
            </div>
            {newProfitsCount > 0 && 
                <NewProfitsInfo count={newProfitsCount} onClick={moveToStart}/>
            }
        </div>
    );
};

export default ProfitList;