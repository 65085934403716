import { pxToRem } from "../../utils/convert";

function ArrowIcon({ width = 12, height = 9, className }) {
    return ( 
        <div style={{width: pxToRem(width), height: pxToRem(height)}}>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M6.80583 7.90334C6.40623 8.44716 5.59376 8.44716 5.19415 7.90334L1.10773 2.34214C0.62245 1.68172 1.09403 0.750001 1.91357 0.750001L10.0864 0.75C10.906 0.75 11.3775 1.68172 10.8923 2.34214L6.80583 7.90334Z" fill="#075BA1"/>
            </svg>
        </div>
     );
}

export default ArrowIcon;