import { pxToRem } from "../../utils/convert";

function RubIcon({ width = 11, height = 12, className }) {
    return ( 
        <div style={{width: pxToRem(width), height: pxToRem(height)}}>
            <svg
                style={{width: pxToRem(width), height: pxToRem(height)}}
                className={className}
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path opacity="0.5" d="M4.06738 1.59082V5.95703H5.81055C7.4292 5.95703 8.23438 5.11035 8.23438 3.76562C8.23438 2.4292 7.4375 1.59082 5.81885 1.59082H4.06738ZM7.4458 10.1987H4.06738V12H2.20801V10.1987H0.605957V8.92041H2.20801V7.45947H0.605957V5.96533H2.20801V0.0219727H6.3335C8.64111 0.0219727 10.1353 1.60742 10.1353 3.75732C10.1353 5.90723 8.59961 7.46777 6.27539 7.46777H4.06738V8.92041H7.4458V10.1987Z" fill="white"/>
            </svg>
        </div>
     );
}

export default RubIcon;