import ArrowIcon from "../Images/Arrow";
import styles from "./index.module.scss";
import { formatReplenishments } from "../../utils/convert";

function NewProfitsInfo( { count, onClick }) {
    return (
        <button className={styles["new-profits-info"]} onClick={onClick}>
            <div className={styles["new-profits-info__circle"]}>
                <ArrowIcon className={styles["new-profits-info__icon"]}/>
            </div>
            <div className={styles["new-profits-info__text"]}>
                {formatReplenishments(count)}
            </div>
        </button>
    );
}

export default NewProfitsInfo;