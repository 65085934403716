import { useState } from "react";
import styles from "./index.module.scss";
import BackIcon from "../Images/Back";
import SearchIcon from "../Images/Search";
import classnames from "classnames"

function SearchButton( { isOpen, onClick }) {

    return (
        <button className={classnames(styles.button, isOpen && styles["button--opened"])} onClick={onClick}>
            {isOpen ? <BackIcon/> : <SearchIcon/>}
            <div>{isOpen ? "Назад" : "Поиск"}</div>
        </button>
    );
}

export default SearchButton;