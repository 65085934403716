import React from 'react';
import styles from './index.module.scss';
import TickIcon from '../Images/Tick';
import classNames from 'classnames';
function Checkbox({ label, checked, onChange }) {
    return (
        <label className={styles.label}>
            <div className={styles.checkbox}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className={styles.checkbox__input}
            />
            <span className={styles.checkbox__box}>
                {checked && <TickIcon/>}
            </span>
            <span className={classNames(styles.checkbox__label, checked && styles["checkbox__label--checked"])}>{label}</span>
            </div>

        </label>
    );
}

export default Checkbox;
