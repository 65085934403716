import React from 'react';
import ProfitItem from './ProfitItem';
import SkeletonProfitItem from './ProfitItem/Skeleton';

export const ListContent = ({ loading, profits }) => {
    if (loading && profits.length === 0) {
        return Array.from({ length: 20 }).map((_, index) => (
            <SkeletonProfitItem key={`skeletonProfitItem${index}`} id={index} />
        ));
    }

    return (
        <>
            {profits.map((profit, index) => (
                <ProfitItem key={`profitItem${profit.id}-${index}`} data={profit} />
            ))}
            {profits.length > 3 && loading && (
                <SkeletonProfitItem key={`skeletonProfitItem`} id={"Skeleton"} />
            )}
        </>
    );
};