import { useState, useEffect } from "react";

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      // Добавляем обработчик события resize
      window.addEventListener('resize', handleResize);
  
      // Удаляем обработчик при размонтировании компонента
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowSize;
  }
  